import { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6, Paragraph, StrongText, SmallText, MicroText } from '@sb1/ffe-core-react';
import * as React from 'react';
export default {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  Paragraph,
  StrongText,
  SmallText,
  MicroText,
  React
};