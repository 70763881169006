import fargevekting2kombo from "../../../../static/img/farger/fargevekting-2fargekombo.png";
import fargevekting3kombo from "../../../../static/img/farger/fargevekting-3fargekombo.png";
import fargevekting4kombo from "../../../../static/img/farger/fargevekting-4fargekombo.png";
import fargevektingIkkeLov from "../../../../static/img/farger/fargevekting-ikke-lov.png";
import * as React from 'react';
export default {
  fargevekting2kombo,
  fargevekting3kombo,
  fargevekting4kombo,
  fargevektingIkkeLov,
  React
};