import bidraIFigma1 from "../../../../static/img/bidra-i-figma/bidra-i-figma1.png";
import bidraIFigma2 from "../../../../static/img/bidra-i-figma/bidra-i-figma2.png";
import bidraIFigma3 from "../../../../static/img/bidra-i-figma/bidra-i-figma3.png";
import bidraIFigma4 from "../../../../static/img/bidra-i-figma/bidra-i-figma4.png";
import bidraIFigma5 from "../../../../static/img/bidra-i-figma/bidra-i-figma5.png";
import bidraIFigma6 from "../../../../static/img/bidra-i-figma/bidra-i-figma6.png";
import bidraIFigma7 from "../../../../static/img/bidra-i-figma/bidra-i-figma7.png";
import bidraIFigma8 from "../../../../static/img/bidra-i-figma/bidra-i-figma8.png";
import * as React from 'react';
export default {
  bidraIFigma1,
  bidraIFigma2,
  bidraIFigma3,
  bidraIFigma4,
  bidraIFigma5,
  bidraIFigma6,
  bidraIFigma7,
  bidraIFigma8,
  React
};