import familieLeker from "../../../../static/img/bilder/familie-leker.jpg";
import flytteesker from "../../../../static/img/bilder/flytteesker.jpg";
import snekker from "../../../../static/img/bilder/snekker.jpg";
import tomatbonde from "../../../../static/img/bilder/tomatbonde.jpg";
import * as React from 'react';
export default {
  familieLeker,
  flytteesker,
  snekker,
  tomatbonde,
  React
};