import formerGeometriske from "../../../../static/img/former/former-geometriske.png";
import formerOrganiske1 from "../../../../static/img/former/former-organiske1.png";
import formerOrganiske2 from "../../../../static/img/former/former-organiske2.png";
import formerAvrundetRektangulaere1 from "../../../../static/img/former/former-avrundet-rektangulaere1.png";
import formerAvrundetRektangulaere2 from "../../../../static/img/former/former-avrundet-rektangulaere2.png";
import * as React from 'react';
export default {
  formerGeometriske,
  formerOrganiske1,
  formerOrganiske2,
  formerAvrundetRektangulaere1,
  formerAvrundetRektangulaere2,
  React
};