import bankenesLogo from "../../../../static/img/logo/bankenes-logo.png";
import fellesLogo from "../../../../static/img/logo/felles-logo.png";
import logosymbol from "../../../../static/img/logo/logosymbol.png";
import farger1 from "../../../../static/img/logo/farger1.png";
import farger2 from "../../../../static/img/logo/farger2.png";
import kontrast from "../../../../static/img/logo/kontrast.png";
import ikkeEndreLogoene from "../../../../static/img/logo/ikke-endre-logoene.png";
import beskyttelsesomrade from "../../../../static/img/logo/beskyttelsesomrade.png";
import plassering from "../../../../static/img/logo/plassering.png";
import * as React from 'react';
export default {
  bankenesLogo,
  fellesLogo,
  logosymbol,
  farger1,
  farger2,
  kontrast,
  ikkeEndreLogoene,
  beskyttelsesomrade,
  plassering,
  React
};