import andreMerkevarer from "../../../../static/img/ikoner/andre-merkevarer.png";
import darkMode from "../../../../static/img/ikoner/dark-mode.png";
import lightMode from "../../../../static/img/ikoner/light-mode.png";
import materialSymbols from "../../../../static/img/ikoner/material-symbols.png";
import narBrukerViBilder from "../../../../static/img/ikoner/nar-bruker-vi-bilder.png";
import narBrukerViIkoner from "../../../../static/img/ikoner/nar-bruker-vi-ikoner.png";
import narBrukerViIllustrasjoner from "../../../../static/img/ikoner/nar-bruker-vi-illustrasjoner.png";
import konsekventBruk1 from "../../../../static/img/ikoner/konsekvent-bruk1.png";
import konsekventBruk2 from "../../../../static/img/ikoner/konsekvent-bruk2.png";
import { Icon } from '@sb1/ffe-icons-react';
import * as React from 'react';
export default {
  andreMerkevarer,
  darkMode,
  lightMode,
  materialSymbols,
  narBrukerViBilder,
  narBrukerViIkoner,
  narBrukerViIllustrasjoner,
  konsekventBruk1,
  konsekventBruk2,
  Icon,
  React
};