import * as ffeFormatters from '@sb1/ffe-formatters';
import { Table, TableHead, TableBody, TableHeaderCell, TableRow, TableDataCell } from '@sb1/ffe-tables-react';
import * as React from 'react';
export default {
  ffeFormatters,
  Table,
  TableHead,
  TableBody,
  TableHeaderCell,
  TableRow,
  TableDataCell,
  React
};