import fargebrukEksempel1 from "../../../../static/img/illustrasjoner/fargebruk-eksempel1.png";
import fargebrukEksempel2 from "../../../../static/img/illustrasjoner/fargebruk-eksempel2.png";
import fargebrukEksempel3 from "../../../../static/img/illustrasjoner/fargebruk-eksempel3.png";
import strektykkelseDetailnivaEksempel1 from "../../../../static/img/illustrasjoner/strektykkelse-detaljniva-eksempel1.png";
import strektykkelseDetailnivaEksempel2 from "../../../../static/img/illustrasjoner/strektykkelse-detaljniva-eksempel2.png";
import proposjonerEksempel1 from "../../../../static/img/illustrasjoner/proposjoner-eksempel1.png";
import formatEksempel1 from "../../../../static/img/illustrasjoner/format-eksempel1.png";
import formatEksempel2 from "../../../../static/img/illustrasjoner/format-eksempel2.png";
import komposisjonEksempel1 from "../../../../static/img/illustrasjoner/komposisjon-eksempel1.png";
import negativeFormerEksempel1 from "../../../../static/img/illustrasjoner/negative-former-eksempel1.png";
import negativeFormerEksempel2 from "../../../../static/img/illustrasjoner/negative-former-eksempel2.png";
import negativeFormerEksempel3 from "../../../../static/img/illustrasjoner/negative-former-eksempel3.png";
import negativeFormerEksempel4 from "../../../../static/img/illustrasjoner/negative-former-eksempel4.png";
import riktigLagRekkefolgeEksempel1 from "../../../../static/img/illustrasjoner/riktig-lag-rekkef\xF8lge-eksempel1.png";
import * as React from 'react';
export default {
  fargebrukEksempel1,
  fargebrukEksempel2,
  fargebrukEksempel3,
  strektykkelseDetailnivaEksempel1,
  strektykkelseDetailnivaEksempel2,
  proposjonerEksempel1,
  formatEksempel1,
  formatEksempel2,
  komposisjonEksempel1,
  negativeFormerEksempel1,
  negativeFormerEksempel2,
  negativeFormerEksempel3,
  negativeFormerEksempel4,
  riktigLagRekkefolgeEksempel1,
  React
};