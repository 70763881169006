require('prismjs/themes/prism.css');
const {
    initGlobalTracker,
    AppContextEnum,
    BankEnum,
    BackendEnvironmentEnum
} = require('@sb1/web-behavior-tracker');
const {DestinationEnum} = require('@sb1/web-behavior-tracker/src/types/enums');

exports.onClientEntry = () => {
    initGlobalTracker(() => ({
        user: {
            anonymousId: '',
            sessionId: '',
            optins: {
                functional: false,
                statistics: true,
                marketing: false
            },
            language: 'nb'
        },
        destinations: [DestinationEnum.Humio],
        bank: BankEnum.All,
        application: {
            name: 'designsystem-cms',
            context: AppContextEnum.intern
        },
        disableTrackingCookies: true,
        backendEnvironment: process.env.NODE_ENV === 'development'
            ? BackendEnvironmentEnum.Test
            : BackendEnvironmentEnum.Prod
    }));
};
